const productSearchKeys = [
  { label: 'common.FGAdrress', value: '1' },
  {
    label: 'common.orderNo',
    value: '2',
  },
]
const bujianSearchKeys = [
  { label: 'common.plankNum', value: 'plankNum' },
  {
    label: 'common.plankNo',
    value: 'plankID',
  },
]

const bujianStoreSearchKeys = [
  { label: 'common.plankNum', value: '1' },
  {
    label: 'common.plankNo',
    value: '2',
  },
]

export { productSearchKeys, bujianSearchKeys, bujianStoreSearchKeys }
