import { render, staticRenderFns } from "./g-folder-choose-modal.vue?vue&type=template&id=4512b124&scoped=true&"
import script from "./g-folder-choose-modal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./g-folder-choose-modal.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4512b124",
  null
  
)

export default component.exports